import React from 'react';
import Container from '@mui/material/Container';
import SelectionList from '../../components/selectionList';
import Subtitle from '../../components/subtitle';
import SubParagraph from '../../components/subParagraph';

export default function StepTwo() {
  return (
    <Container
      sx={{
        px: 0
      }}
    >
      <Subtitle>
        Select damaged items
      </Subtitle>

      <Container 
        maxWidth="md"
        sx={{
          px: 0
        }}
      >
        <SubParagraph align="justify">
          From the table below, please indicate which items and the 
          quaintity that were damaged.
        </SubParagraph>
      </Container>
      <SelectionList />
    </Container>
  );
}