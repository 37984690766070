/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Container from '@mui/material/Container';
import { Formik, Form } from 'formik';
import Subtitle from '../../components/subtitle';
import SubParagraph from '../../components/subParagraph';
import Input from '../../components/form/input';
import { Dropdown } from '../../components/form/dropdown';
import { personalInfoValidationSchema } from "../../validations/personalInfoValidations";
import { setPersonalInformation } from "../../slices/wholesaleSlice";
import { COUNTRIES, STATES } from '../../constants';

export default function StepFive() {
  const { personalInformation } = useSelector((state) => state.wholesale);
  const dispatch = useDispatch();
  
  const [personalInfo, setPersonalInfo] = useState(personalInformation);
  const [states, setStates] = useState(STATES[personalInfo.country] || []);

  const handleSetPersonalInformation = (event) => {
    if(event.target.name == "name") {
      setPersonalInfo({...personalInformation, name: event.target.value});
    } else if(event.target.name == "email") {
      setPersonalInfo({...personalInformation, email: event.target.value});
    } else if(event.target.name == "phone") {
      setPersonalInfo({...personalInformation, phone: event.target.value});
    } else if(event.target.name == "address_one") {
      setPersonalInfo({...personalInformation, addressOne: event.target.value});
    } else if(event.target.name == "address_two") {
      setPersonalInfo({...personalInformation, addressTwo: event.target.value});
    } else if(event.target.name == "country") {
      setPersonalInfo({...personalInformation, country: event.target.value, state: ''});
    } else if(event.target.name == "state_province") {
      setPersonalInfo({...personalInformation, state: event.target.value});
    } else if(event.target.name == "city") {
      setPersonalInfo({...personalInformation, city: event.target.value});
    } else if(event.target.name == "zipCode") {
      setPersonalInfo({...personalInformation, zipCode: event.target.value});
    }
  }

  useEffect(() => {
    dispatch(setPersonalInformation(personalInfo))
    setStates(STATES[personalInfo.country])
  }, [personalInfo]);

  return (
    <Container 
      maxWidth="md"
      sx={{
        px: 0
      }}
    >
      <Subtitle>
        Personal information
      </Subtitle>

      <SubParagraph align="justify">
        Please provide us with the following informaiton so we may expedite the 
        shipment of your replacement item(s).
      </SubParagraph>
      <Container 
        maxWidth="sm"
        sx={{
          marginTop: 6,
          px: 0
        }}
      >
        <Formik initialValues = {
            {
              name: "",
              email: "",
              phone: "",
              address_one: "",
              address_two: "",
              country: "",
              city: "",
              state_province: "",
              zipCode:""
            }
          }
          validateOnMount = {false}
          validationSchema = {personalInfoValidationSchema}
        >
          {({
            values, 
            errors
          })=>{
            return(
              <Form>
                <Input onChange={handleSetPersonalInformation} value={personalInformation.name} label="NAME" name="name" placeholder="Name" style={{marginTop: "24px", maxWidth: "300px"}}/>
                <Input onChange={handleSetPersonalInformation} value={personalInformation.email} label="EMAIL ADDRESS" name="email" placeholder="Email address" style={{marginTop: "24px", maxWidth: "300px"}}/>
                <Input onChange={handleSetPersonalInformation} value={personalInformation.phone} label="PHONE (OPTIONAL)" name="phone" placeholder="123-456-7890" style={{marginTop: "24px", maxWidth: "300px"}}/>
                <Input onChange={handleSetPersonalInformation} value={personalInformation.addressOne} label="ADDRESS 1" name="address_one" placeholder="Address line 1" style={{marginTop: "24px"}}/>
                <Input onChange={handleSetPersonalInformation} value={personalInformation.addressTwo} label="ADDRESS 2 (OPTIONAL)" name="address_two" placeholder="Address line 2"  style={{marginTop: "24px"}}/>
                <Dropdown onChange={handleSetPersonalInformation} value={personalInformation.country} label="COUNTRY" name="country" placeholder="Country" options={COUNTRIES} style={{marginTop: "24px", maxWidth: "300px"}}/>
                <Dropdown onChange={handleSetPersonalInformation} value={personalInformation.state} label="STATE/PROVINCE" name="state_province" placeholder="Other" options={states} style={{marginTop: "24px", maxWidth: "300px"}}/>
                <Input onChange={handleSetPersonalInformation} value={personalInformation.city} label="CITY" name="city" placeholder="City" style={{marginTop: "24px", maxWidth: "300px"}}/>
                <Input onChange={handleSetPersonalInformation} value={personalInformation.zipCode} label="ZIP/POSTAL CODE" name="zipCode" placeholder="Zip/Postal code" style={{marginTop: "24px", maxWidth: "140px"}}/>
              </Form>
            )
          }}
        </Formik>
      </Container>
    </Container>
  );
}
/* eslint-enable */