import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../app/api';
import { v4 as uuid } from 'uuid';

const DTC_STEPS = [
  "Enter you web order number",
  "Select damaged items",
  "Please provide up to two images for each of the items you have selected",
  "Provide additional information"
];
const WHOLESALE_STEPS = [
  "Enter purchase information",
  "List damaged items",
  "Please provide up to two images for each of the items you have selected",
  "Provide additional information",
  "Personal information"
];

const itemObject = {
  item_image_url: "",
  item_id: '',
  description: "",
  quantity: 0,
  tracking_number: '',
  piecesDamaged: 0,
  problemText: "",
  images: [],
  imageKeys: [],
  isSelected: true
}

const initialState = {
  status: 'idle',
  steps: WHOLESALE_STEPS,
  items: [],
  activeStep: 0,
  latestStep: 0,
  tryCount: 0,
  caseNumber: "",
  brand: '',
  additionalInformation: "",
  isDtc: false,
  isCostco: false,
  isWholesale: false,
  loading: false,
  error: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeAdditionalInformation: (state, { payload }) => {
      state.additionalInformation = payload;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload || 0;
      if(state.activeStep >= state.latestStep) {
        state.latestStep = state.activeStep;
      }
    },
    setLatestStep: (state, action) => {
      state.latestStep = action.payload || 0;
    },
    setLoading: (state, action) => {
      state.loading = action.payload || false;
    },
    setError: (state, action) => {
      state.error = action.payload || false;
    },
    setSteps: (state, action) => {
      "dtc" === action.payload ?
      state.steps = DTC_STEPS :
      state.steps = WHOLESALE_STEPS
    },
    addItem: (state) => {
      const newItem = {...itemObject, uniqueItemId: uuid()};
      state.items = [...state.items, newItem];
    },
    deleteItem: (state, { payload }) => {
      state.items = state.items.filter((item, index) => payload !== index);
    },
    setAppTypeValues: (state, action) => {
      state.brand = action.payload.brand || '';
      state.isDtc = action.payload.isDtc;
      state.isCostco = action.payload.isCostco;
      state.isWholesale = action.payload.isWholesale;
    }, 
    selectAllItems: (state) => {
      const isSome = state.items.some(item => item.isSelected)
      state.items = state.items.map(item => (isSome ? {...item, isSelected: false} : {...item, isSelected: !item.isSelected}));
    },
    selectItem: (state, { payload }) => {
      state.items = state.items.map(item => payload === item.item_id ? {...item, isSelected: !item.isSelected } : {...item});
    },
    uploadImage: (state, { payload }) => {
      state.items = state.items.map(item => {
        if (payload.item_id === item.item_id) {
          return {...item, images: payload.images, imageKeys: payload.imageKeys }
        }
        return {...item};
      });
    },
    setDamagedPieces: (state, { payload }) => {
      state.items = state.items.map((item, index)  => {
        if (payload.index === index) {
          let decoratedPieces = payload.piecesDamaged < 0 ? 0 : payload.piecesDamaged;
          return {...item, piecesDamaged: decoratedPieces }
        }
        return {...item};
      });
    },
    setItemId: (state, { payload }) => {
      state.items = state.items.map((item, index) => {
        if (payload.index === index) {
          return {...item, item_id: payload.item_id }
        }
        return {...item};
      });
    },
    setProblemText: (state, { payload }) => {
      state.items = state.items.map((item, index)  => {
        if (payload.index === index) {
          return {...item, problemText: payload.problemText }
        }
        return {...item};
      });
    },
    setItems: (state, { payload }) => {
      state.items = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(completeSubmission.pending, (state, action) => {
        state.status = 'loading';
        state.loading = true;
      })
      .addCase(completeSubmission.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isCompleted = true;
        state.loading = false;
        if(state.tryCount === 0) {
          state.activeStep += 1;
        }
        state.caseNumber = action.payload.data.case_id;
      })
      .addCase(completeSubmission.rejected, (state, action) => {
        state.status = 'failed';
        state.isCompleted = false;
        state.loading = false;
        if(state.tryCount === 0) {
          state.activeStep += 1;
        }
        state.error = action.error.message;
        state.tryCount += 1;
      })
  }
});

export const { 
  setItems,
  uploadImage,
  selectAllItems,
  selectItem,
  addItem,
  deleteItem,
  setDamagedPieces,
  setActiveStep,
  setSteps,
  setProblemText,
  setLatestStep,
  setAppTypeValues,
  setLoading,
  setError,
  changeAdditionalInformation,
  setItemId
} = appSlice.actions;

export const completeSubmission = createAsyncThunk('completeSubmission', async (data) => {
  const response = await api.post('/case', data)
  return response
});

export default appSlice.reducer;