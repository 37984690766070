import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

export default function Input ({
	name,
	placeholder,
	id,
	type,
	label,
	disabled,
	validated,
	touched,
	errorMessage,
	...inputProps
}) {
	function endAdorment (validated, touched) {
		let fragment;
		if(!validated && touched) {
			fragment = <InputAdornment position="end">
							<CloseIcon
								sx={{
									color: 'warning.main',
									fontSize:"20px!important"
								}}
							/>
						</InputAdornment>;
		} else if(validated && touched) {
			fragment = <InputAdornment position="end">
					  <DoneIcon 
						sx={{
							color: 'submissionSuccess',
							fontSize:"20px!important"
						  }}
					  />
					</InputAdornment>;
		} else {
			fragment = <div/>;
		}
		return fragment;
	}
	return (
		<Box name={name} {...inputProps}>
			<FormHelperText 
				id="outlined-weight-helper-text"
				sx={{ fontWeight: 'bold' }}
			>
				{label}
			</FormHelperText>
			<TextField
				sx={{ 
					width: '100%',
					backgroundColor: 'white',
					borderColor: 'warning.main',
					"& .MuiInputBase-root": {
						height: 50
					}
				}}
				disabled={disabled}
				InputProps={{
					endAdornment: endAdorment(validated, touched),
					style: {
						backgroundColor: 'white',
						borderColor: 'warning.main'
					}
				}}
				type={type}
				name={name}
				id={id}
				placeholder={placeholder}
				aria-invalid='true'
				error={!validated && touched}
			/>

			{(!validated && touched) && (
				<FormHelperText 
					id="outlined-weight-helper-text"
					sx={{
						color: 'warning.main'
					}}
				>
					{errorMessage}
				</FormHelperText>
			)}
		</Box>
	)
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.number
  ]),
};

Input.defaultProps = {
  placeholder: '',
  label: '',
  id: '',
  type: 'text',
  value: '',
};