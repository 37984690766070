import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { grey } from '@mui/material/colors';
import { Formik } from 'formik';
import Input from './form/input';
import { FormText } from './form/textarea';
import { selectAllItems, selectItem, setDamagedPieces, setProblemText } from "../slices/appSlice";
import { itemValidationSchema } from "../validations/itemValitdations";

function TableTitle({children, minWidth, maxWidth, display}) {
  return(
    <TableCell
        sx={{
            color: grey[500],
            fontWeight: 700,
            fontSize: 13,
            minWidth,
            maxWidth,
            display
        }}
    >
      {children}
    </TableCell>
)};
function TableItem({children, minWidth, maxWidth, display}) {
  return(
    <TableCell
        style={{ verticalAlign: 'top' }}
        sx={{
            fontWeight: 700,
            fontSize: 14,
            color: "paragraph",
            minWidth,
            maxWidth,
            display,
            border: 0
        }}
    >
      {children}
    </TableCell>
)};


export default function SelectionList() {
  const { items } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const helperText = "Please describe the damage to this item, or if this item is part of a set (ex. 32 piece dinnerware set), please comment on the specific item(s) from the set that were damaged (ex. 1 dinner plate plus 1 bowl).";
  const [isAllChecked, setIsAllChecked] = useState(items.every(item => item.isSelected))
  const [isSomeChecked, setIsSomeChecked] = useState(!isAllChecked && items.some(item => item.isSelected))

  const handleAllCheck = () => {
    dispatch(selectAllItems());
  };
  const handleItemCheck = (item_id) => {
    dispatch(selectItem(item_id));
  };
  const handleDamagedPiecesChange = (value, index, max) => {
    const decoratedNumber = value > max ? max :value
    dispatch(setDamagedPieces({
      piecesDamaged: decoratedNumber,
      index: index
    }));
  };
  const handleProblemTextChange = (value, index) => {
    dispatch(setProblemText({
      problemText: value,
      index: index
    }));
  };
  

  useEffect(() => {
    setIsAllChecked(items.every(item => item.isSelected));
    setIsSomeChecked(!isAllChecked && items.some(item => item.isSelected));
  }, [items, isAllChecked])

  return (
     <TableContainer sx={{ mt: 7 }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                checked={isAllChecked}
                indeterminate={isSomeChecked}
                onChange={handleAllCheck}
                sx={{
                    color: grey[400],
                    background: "white",
                    transform: "scale(0.75)",
                    padding: 0,
                }}
              />
            </TableCell>
            <TableTitle minWidth={100}>ITEM IMAGE / ITEM ID</TableTitle>
            <TableTitle>DESCRIPTION</TableTitle>
            <TableTitle display={{ xs: 'none', md: 'table-cell' }}>PIECES SHIPPED</TableTitle>
            <TableTitle display={{ xs: 'none', md: 'table-cell' }}>TRACKING NUMBER</TableTitle>
            <TableTitle display={{ xs: 'none', md: 'table-cell' }}>PIECES DAMAGED</TableTitle>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row, index) => (
            <Formik 
              initialValues = {
                {
                  piecesDamaged: row.piecesDamaged,
                  problemText: row.problemText
                }
              }
              validateOnMount = {false}
              validationSchema = {itemValidationSchema}
              key = {row.item_id}
            >
              {()=>(
                  <>
                    <TableRow
                      key={row.item_id}
                    >
                      <TableCell component="th" scope="row" sx={{ border: 0 }}>
                        <Checkbox 
                          checked={row.isSelected}
                          onChange={() => {
                            handleItemCheck(row.item_id);
                          }}
                          sx={{
                            color: grey[400],
                            transform: "scale(0.75)",
                            padding: 0
                          }} 
                        />
                      </TableCell>
                      <TableItem minWidth={100}>
                        <Box
                          component="div"
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "start"
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              boxShadow: 3,
                              width: '5rem',
                              bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                              p: 0.3,
                              mr:2,
                              borderRadius: "5px",
                            }}
                            alt={row.description}
                            src={row.item_image_url} 
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src=require('../assets/images/default_image.png');
                            }}
                          />
                          <Box marginTop={2}>{row.item_id}</Box>
                        </Box>
                      </TableItem>
                      <TableItem maxWidth={200}><Box marginTop={2}>{row.description}</Box></TableItem>
                      <TableItem maxWidth={80} display={{ xs: 'none', md: 'table-cell' }}><Box marginTop={2} textAlign="center">{row.quantity}</Box></TableItem>
                      <TableItem maxWidth={120} display={{ xs: 'none', md: 'table-cell' }}><Box marginTop={2}>{row.tracking_number}</Box></TableItem>
                      <TableItem maxWidth={100} display={{ xs: 'none', md: 'table-cell' }}>
                        <Input 
                          name="piecesDamaged" 
                          disabled={row.isSelected ? false : true} 
                          value={row.piecesDamaged}
                          type="number"
                          onChange={(e) => {
                            handleDamagedPiecesChange(e.target.value, index, row.quantity);
                          }}/>
                      </TableItem>
                    </TableRow>
                    <TableRow
                      sx={{
                        border: 0,
                        p: 0
                      }}
                    >
                      <TableCell 
                        sx={{
                          border: 0,
                          display: {xs: 'table-cell', md: 'none'}
                        }} 
                      />
                      <TableTitle display={{ xs: 'table-cell', md: 'none' }}>PIECES SHIPPED</TableTitle>
                      <TableTitle display={{ xs: 'table-cell', md: 'none' }}>PIECES DAMAGED</TableTitle>
                    </TableRow>
                    <TableRow
                      sx={{
                        border: 0,
                        p: 0
                      }}
                    >
                      
                      <TableCell 
                        sx={{
                          border: 0,
                          display: {xs: 'table-cell', md: 'none'}
                        }} 
                      />
                      <TableItem display={{ xs: 'table-cell', md: 'none' }}><Box marginTop={2} textAlign="left">{row.quantity}</Box></TableItem>
                      <TableItem display={{ xs: 'table-cell', md: 'none' }}>
                        <Input 
                            name="piecesDamaged" 
                            disabled={row.isSelected ? false : true} 
                            value={row.piecesDamaged}
                            type="number"
                            onChange={(e) => {
                              handleDamagedPiecesChange(e.target.value, index, row.quantity);
                            }}/>
                      </TableItem>
                    </TableRow>
                    <TableRow
                      sx={{
                        borderBottom: "1px solid",
                        borderColor: grey[300]
                      }}
                    >
                      <TableCell component="th" scope="row" />
                      <TableCell 
                        component="th" 
                        colSpan={6} 
                        sx={{
                          p: 2
                        }}>
                        { row.isSelected &&
                          <FormText 
                            label="THE PROBLEM WITH THE ITEMS" 
                            name="problemText" 
                            placeholder="" 
                            rows="3"
                            width={{ xs: "100%", md: "500px" }}
                            helperText={helperText}
                            onChange={(e) => {
                              handleProblemTextChange(e.target.value, index);
                            }}/>
                        }
                      </TableCell>
                    </TableRow>
                  </>
                )
              }
            </Formik>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TableTitle.propTypes = {
  children: PropTypes.string,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
};

TableTitle.defaultProps = {
  children: "",
  minWidth: 0,
  maxWidth: 1000,
};

TableItem.propTypes = {
  children: PropTypes.instanceOf(Object),
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
};

TableItem.defaultProps = {
  children: <div />,
  minWidth: 0,
  maxWidth: 1000,
};

SelectionList.propTypes = {
  list: PropTypes.instanceOf(Array),
};

SelectionList.defaultProps = {
  list: [],
};
