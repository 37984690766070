import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Container from '@mui/material/Container';
import CustomRouter from './containers/customRouter';
import Wholesale from './containers/wholesale';
import NoPage from './containers/noPage';
import ProductReplacementTheme from './app/theme';

function App() {
  return (
    <ProductReplacementTheme>
      <Container maxWidth="xl" sx={{ pb: "150px" }}>
        <BrowserRouter>
            <Routes>
              <Route path="/" element={<Wholesale />} />
              <Route path="/:brand" element={<CustomRouter />} />
              <Route path="*" element={<NoPage />} />
            </Routes>
        </BrowserRouter>
      </Container>
    </ProductReplacementTheme>
  );
}

export default App;
