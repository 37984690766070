import { string, object, date } from 'yup';

const retailerValidationSchema = object({
    purchaseDate: date()
    .nullable()
    .required("Please choose purchase date"),
    retailer: string()
    .nullable()
    .required("Please choose retailer where you purchased"),
    retailerName: string()
    .nullable()
    .required("Please enter retailer name")
    .min(5, "Minimum 5 characters required")
    .max(100, "Maximum 100 characters allowed")
  });

export {
    retailerValidationSchema
};
