import { configureStore } from '@reduxjs/toolkit';
import wholesaleReducer from '../slices/wholesaleSlice';
import dtcReducer from '../slices/dtcSlice';
import appReducer from '../slices/appSlice';

export const store = configureStore({
  reducer: {
    wholesale: wholesaleReducer,
    dtc: dtcReducer,
    app: appReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
