
import Dtc from './dtc';
import NoPage from './noPage';
import { useParams } from "react-router-dom";
import { DTC_BRANDS } from '../constants';

export default function CustomRouter() {
  const { brand } = useParams();
  const isDtc = (DTC_BRANDS.includes(brand));
  return (
    isDtc ? <Dtc /> : <NoPage />
  );
}
