import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Header from '../../components/header';
import StepButtons from '../../components/stepButtons';
import Stepper from '../../components/stepper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import StepThree from './stepThree';
import StepFour from './stepFour';
import StepFive from './stepFive';
import StepFinal from './stepFinal';
import { setSteps } from "../../slices/appSlice";

export default function Wholesale() {
  const { activeStep } = useSelector((state) => state.app);
  const [activePage, setActivePage] = useState(<div />);
  const dispatch = useDispatch();
  useEffect(() => {
    if(activeStep === 1) {
      setActivePage(<StepTwo />);
    } else if(activeStep === 2){
      setActivePage(<StepThree />);
    } else if(activeStep === 3){
      setActivePage(<StepFour/>);
    } else if(activeStep === 4){
      setActivePage(<StepFive/>);
    }  else if(activeStep === 5){
      setActivePage(<StepFinal/>);
    } else {
      setActivePage(<StepOne/>);
    }
    dispatch(setSteps("wholesale"));
  }, [activeStep, dispatch]);
  return (
    <>
      <Header />
      <Container 
        maxWidth="md"
        sx={{
          px: 0
        }}
      >
        <Typography
          variant="h3"
          component="h4"
          sx={{
            textAlign:"center",
            fontWeight: 700,
            width: "100%",
            marginTop: 4,
            marginBottom: 6,
            color: "paragraph"
          }}>
          We are here to help
        </Typography>
        <Stepper />
      </Container>
      <Container 
        maxWidth="lg"
        sx={{
          px: 0
        }}
      >
        {activePage}
        <StepButtons />
      </Container>
    </>
  );
}
