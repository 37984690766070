import React from 'react';
import Container from '@mui/material/Container';
import Subtitle from '../../components/subtitle';
import SubParagraph from '../../components/subParagraph';
import DamagedItemsList from '../../components/damagedItemsList';

export default function StepTwo() {
  return (
    <Container
      sx={{
        px: 0
      }}
    >
      <Subtitle>
        List damaged items
      </Subtitle>
      <Container 
        maxWidth="md"
        sx={{
          px: 0
        }}
      >
        <SubParagraph align="justify">
          Please provide a description and pattern or each item. As well please describe
          the damage to this item, or if this item is part of a set (ex. 32 piece dinnerware 
          set), please comment on the specific items(s) from the set that were damaged (ex. 
          1 dinner plate plus 1 bowl).
        </SubParagraph>
      </Container>
      <DamagedItemsList />
    </Container>
  );
}