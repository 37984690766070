import React, { useState, useEffect }from 'react';
import { useDispatch } from "react-redux";
import AWS from 'aws-sdk';
import { v4 as uuid } from 'uuid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import FormHelperText from '@mui/material/FormHelperText';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { object, mixed } from 'yup';
import { Formik, Form } from 'formik';
import { setError } from "../slices/appSlice";
import FileInput from '../components/form/fileInput';

var folder = 'test';
var hostname = window.location.hostname;
if (hostname === 'replacements.lifetimebrands.com') {
  folder = 'production';
}

const S3_BUCKET = ("lifetime-product-replacement/" + folder);
const REGION = "us-east-1";

const myBucket = new AWS.S3({
    accessKeyId: "AKIAZKZNP5XNWK5OU6ZZ",
    secretAccessKey: "NINyV3wyNFf1Y01/5AEtB23+DqFd8qjjjdHDY352",
    region: REGION
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 9,
    borderRadius: 5,
    width:'50%',
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: 'primary',
    },
  }));

const FILE_SIZE = 10 * 1024 * 1024;
const postValidationSchema = () =>
  object().shape({
    postImg: mixed()
      .nullable()
      .test('fileSize', "Image is too large", value => {
        if (!value) {
          return true;
        }
        return value.size < FILE_SIZE;
      }),
  });

export default function ImageUploader({title, imageArray, imageKeyArray, imageIndex, setImageArray, item_id}) {
    const dispatch = useDispatch();
    const [postImg, setPostImg] = useState(imageArray[imageIndex]);
    const [imageError, setImageError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [progress , setProgress] = useState(0);
    const [progressInfo , setProgressInfo] = useState("Upload");

    useEffect(() => {
        setPostImg(imageArray[imageIndex])
    }, [imageArray, imageIndex]);

    const handleDeleteImage = () => {
        const index = imageArray.indexOf(postImg);
        const newImages = [...imageArray];
        const newImageKeys = [...imageKeyArray];
          if (index > -1) {
            newImages.splice(index, 1);
            newImageKeys.splice(index, 1);
          }
          setImageArray({
              images: newImages,
              imageKeys: newImageKeys,
              item_id: item_id
          });
    }
    const handleUploadImage = (image, key) => {
        const newImages = [...imageArray];
        const newImageKeys = [...imageKeyArray];
        if (imageIndex < newImages.length) {
            newImages[imageIndex] = image;
            newImageKeys[imageIndex] = key;
        } else if (imageIndex < 2){
            newImages.push(image);
            newImageKeys.push(key);
        }
        setImageArray({
            images: newImages,
            imageKeys: newImageKeys,
            item_id: item_id
        });
    }

    const id = `${item_id}_img_${imageIndex}`
    return(
        <Box sx={{ marginTop: 1, marginBottom: 4, width:"100%"}}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                paddingX={1}
            >
                <Box sx={{ fontWeight: 700, color: 'paragraph' }}>
                    {title}
                </Box>
                {postImg &&
                    <Box>
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                fontWeight: 'bold',
                                justifyContent: "center"
                            }}
                            onClick={handleDeleteImage}
                            color="warning.main"
                            underline="none">
                            <DeleteIcon 
                                sx={{
                                    fontSize:"20px!important",
                                    paddingX: "10px"
                                }}
                            />
                            Delete Image
                        </Box>
                    </Box>
                }
            </Box>
            <Formik
                initialValues={{
                postImg: null,
                post: '',
                }}
                validationSchema={postValidationSchema}>
                {formProps => (
              <Form>
                    <label htmlFor={id} className='card-img-top'>
                        {postImg ?
                            <Box
                                component="img"
                                sx={{
                                    marginTop: 2,
                                    border: "1px solid",
                                    borderColor: grey[300],
                                    borderRadius: '14px',
                                    width: '100%'
                                }}
                                alt={"mikasa"}
                                src={postImg}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src=require('../assets/images/default_image.png');
                                }}
                            /> :
                            <Box
                                sx={{
                                    marginTop: 2,
                                    align: 'justify',
                                    border: "2px dashed",
                                    borderColor: grey[300],
                                    borderRadius: '14px',
                                    backgroundColor: 'white',
                                    minHeight: '300px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                { loading ?
                                    <Box sx={{
                                        flexGrow: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center' }}>
                                        <Typography
                                            variant="body1"
                                            align="justify"
                                            fontSize={15}
                                            color="primary"
                                            marginBottom={1}
                                            sx={{
                                                display: "block"
                                            }}
                                        >
                                            {progressInfo}
                                        </Typography>
                                        <BorderLinearProgress 
                                            variant="determinate" 
                                            value={progress} 
                                        />
                                    </Box> :
                                    <Box sx={{ 
                                        flexGrow: 1,
                                        display:'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center' }}>

                                        <BackupOutlinedIcon 
                                            color="primary"
                                            sx={{
                                                fontSize:"45px!important"
                                            }}
                                        />
                                        <Typography
                                            variant="body1"
                                            align="justify"
                                            fontSize={15}
                                            color="primary"
                                            marginBottom={1}
                                            sx={{
                                                display: "block"
                                            }}
                                        >
                                            Upload image
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                        }
                        <FileInput
                            name={id}
                            display='none'
                            id={id}
                            accept='image/jpg, image/jpeg, image/png'
                            onChange={e => {
                                const prevPostImage = postImg;
                                try {
                                    setLoading(true);
                                    setPostImg(null);
                                    setProgress(0);
                                    setProgressInfo("Uploading");
                                    dispatch(setError(null));
                                    const data = e.target.files[0];
                                    const uniqAssetName = uuid();
                                    const validFileExtensions = [".jpg", ".jpeg", ".png"];
                                    var blnValid = false;
                                    var sFileName = data.name;
                                    for (var j = 0; j < validFileExtensions.length; j++) {
                                        var sCurExtension = validFileExtensions[j];
                                        if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() === sCurExtension.toLowerCase()) {
                                            blnValid = true;
                                            break;
                                        }
                                    } 
                                    if (data.size < FILE_SIZE && blnValid) {
                                        formProps.setFieldValue(id, data);
                                        const params = {
                                            Body: data,
                                            Bucket: S3_BUCKET,
                                            Key: uniqAssetName,
                                        };
                                        myBucket.putObject(params)
                                        .on('httpUploadProgress', (evt) => {
                                            setProgressInfo("Uploading...")
                                            setProgress(Math.round((evt.loaded / evt.total) * 100))
                                        })
                                        .send(function(err, resp) {
                                            if (err) {
                                                setImageError("Failed to upload image, please try again");
                                                setPostImg(prevPostImage);
                                                setLoading(false);
                                                dispatch(setError(true));
                                            } else {
                                                formProps.setFieldValue(id, data);
                                                const reader = new FileReader();
                                                reader.onload = () => {
                                                    if (reader.readyState === 2) {
                                                        setPostImg(reader.result);
                                                        handleUploadImage(reader.result, uniqAssetName);
                                                    }
                                                };
                                                reader.readAsDataURL(data);
                                                setImageError(null);
                                                setLoading(false);
                                                dispatch(setError(null));
                                            }
                                        });                 
                                    } else {
                                        setImageError("All images must be .JPEG, .JPG or .PNG with maximum file size of 10 MB.");
                                        setPostImg(prevPostImage);
                                        setLoading(false);
                                        dispatch(setError(true));
                                    }
                                } catch {
                                    setImageError("Failed to upload image, please try again");
                                    setPostImg(prevPostImage);
                                    setLoading(false);
                                    dispatch(setError(true));
                                }
                            }}
                        />
                    </label>
                    {imageError && (
                        <FormHelperText 
                            id="outlined-weight-helper-text"
                            sx={{
                                color: 'warning.main'
                            }}
                        >
                            {imageError}
                        </FormHelperText>
                    )}
              </Form>
            )}
          </Formik>
        </Box>
    );
}