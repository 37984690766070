import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export default function Subtitle({children}) {
  return (
    <Typography 
      variant="h5"
      align="center"
      fontWeight={700}
      marginY={4}
      fontSize={28}
      color="paragraph"
    >
      {children}
    </Typography>
  );
}

Subtitle.propTypes = {
  children: PropTypes.string,
};

Subtitle.defaultProps = {
  children: "",
};