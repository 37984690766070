import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import ImageUploader from './imageUploader';

export default function ImageSelectionList({ items, handleUploadImage, wholesale }) {
  return (
    <Box 
      sx={{
        borderBottom: "1px solid",
        borderColor: grey[300],
        mt:4
      }}
    >
      {items.filter(i => ((wholesale || i.isSelected) && i.piecesDamaged > 0)).map((item, index) => (
        <Box
          key={item.item_id}
          sx={{
            borderBottom: "1px solid",
            borderColor: grey[300],
            mt:4
          }}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              alignItems: "start"
            }}
          >
            {
              item.item_image_url ?
              <Box
                component="img"
                sx={{
                  boxShadow: 3,
                  width: '5rem',
                  bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                  p: 0.3,
                  mr:2,
                  borderRadius: "5px",
                }}
                alt={item.description}
                src={item.item_image_url} 
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src=require('../assets/images/default_image.png');
                }}
              /> :
              <Typography
                variant="body1"
                align="justify"
                fontSize={19}
                color="paragraph"
                sx={{ 
                  fontWeight:700,
                  paddingRight: 5
                }}
              >
                {index + 1}.
              </Typography>
            }
            
            <Box>
              <Typography
                variant="body1"
                align="justify"
                fontSize={19}
                color="paragraph"
                sx={{ fontWeight:700 }}
              >
                {item.item_id}
              </Typography>
              <Typography
                variant="body1"
                align="justify"
                fontSize={19}
                color="paragraph"
                sx={{ fontWeight:700 }}
              >
                {item.description}
              </Typography>
              <Typography
                variant="body1"
                align="justify"
                fontSize={17}
                color="paragraph"
                sx={{ color: "paragraph"}}
              >
                Pieces Damaged: {item.piecesDamaged}
              </Typography>
            </Box>
          </Box>
          <Box 
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ 
              marginTop: 5,
              align: 'justify' 

            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <ImageUploader 
                  title="Image 1"
                  imageArray={item.images}
                  imageKeyArray={item.imageKeys}
                  imageIndex={0}
                  setImageArray={handleUploadImage}
                  item_id={item.item_id}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ImageUploader 
                  title="Image 2 (Optional)"
                  imageArray={item.images}
                  imageKeyArray={item.imageKeys}
                  imageIndex={1}
                  setImageArray={handleUploadImage}
                  item_id={item.item_id}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      ))}
    </Box>
  );
}
