import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import Container from '@mui/material/Container';
import Subtitle from '../../components/subtitle';
import SubParagraph from '../../components/subParagraph';
import ImageSelectionList from '../../components/imageSelectionList';
import { uploadImage } from "../../slices/appSlice";

export default function StepThree() {
  const { items } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const handleUploadImage = (data) => {
    dispatch(uploadImage(data));
  }
  return (
    <Container 
      maxWidth="md"
      sx={{
        px: 0
      }}
    >
      <Subtitle>
        Please provide up to two images for each of the items you 
        have selected
      </Subtitle>
      <SubParagraph align="justify">
        In order to properly upload the image please use your 
        smart phone or have access to your computer or tablet.
        For a Single damaged item provide 1 image of the damaged 
        item. For Multiple damaged items provide 1 image showing 
        ALL damaged items together.
      </SubParagraph>

      <SubParagraph align="justify" mt={3}>
        All images must be .JPEG, .JPG or .PNG with maximum file size of 10 MB.
      </SubParagraph>
      <ImageSelectionList 
        handleUploadImage={handleUploadImage}
        items={items}
        wholesale={true}
      />
    </Container>
  );
}