/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const itemObject = {
  item_image_url: "",
  item_id: null,
  description: "",
  quantity: null,
  tracking_number: null,
  piecesDamaged: null,
  problemText: "",
  images: [],
  imageKeys: [],
  isSelected: false
}

const initialState = {
  status: 'idle',
  brand: '',
  isGift: false,
  orderNumber: "",
  isOrderNumberValidated: false,
  email: "",
  isEmailValidated: false,
  isEmailFailed: false,
  zipCode: "",
  isZipCodeValidated: false,
  loading: false,
  error: null,
};

export const dtcSlice = createSlice({
  name: 'dtc',
  initialState,
  reducers: {
    setOrderNumberValidated: (state, { payload }) => {
      state.isOrderNumberValidated = payload;
    },
    setEmailValidated: (state, { payload }) => {
      state.isEmailValidated = payload ? true : false;
    },
    setZipcodeValidated: (state, { payload }) => {
      state.isZipCodeValidated = payload;
    },
    changeIsGift: {
      reducer: (state, { payload }) => {
        state.status = "idle";
        state.isGift = payload;
      },
      prepare: (value) => ({ payload: value || false }) 
    },
    changeEmail: {
      reducer: (state, { payload }) => {
        state.email = payload;
      },
      prepare: (value) => ({ payload: value || "" }) 
    },
    changeOrderNumber: {
      reducer: (state, { payload }) => {
        state.orderNumber = payload;
      },
      prepare: (value) => ({ payload: value || "" }) 
    },
    changeZipCode: {
      reducer: (state, { payload }) => {
        state.zipCode = payload;
      },
      prepare: (value) => ({ payload: value || "" }) 
    },
  }
});

export const { 
  changeEmail, 
  changeIsGift, 
  changeOrderNumber, 
  changeZipCode,
  setOrderNumberValidated,
  setEmailValidated,
  setZipcodeValidated,
} = dtcSlice.actions;

export default dtcSlice.reducer;
/* eslint-enable */