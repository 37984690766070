import { string, number, object } from 'yup';

const itemValidationSchema = object({
  item_id: string()
    .nullable()
    .required("Can't be blank")
    .min(5, "Minimum 5 characters required")
    .max(50, "Maximum 50 characters allowed"),
  piecesDamaged: number()
    .moreThan(0, "Can't be 0")
    .nullable()
    .required("Can't be empty"),
  problemText: string()
    .nullable()
    .required("Please provide a description of what is wrong with the items that were shipped to you")
    .min(4, "Minimum 4 characters required")
    .max(1000, "Maximum 1000 characters allowed")
});

export {
  itemValidationSchema
};
