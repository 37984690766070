import { Field } from 'formik';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import { grey } from '@mui/material/colors';

export const FormText = ({
	name,
	placeholder,
	type,
	id,
	label,
	helperText,
	defaultValue,
	rows,
	...inputProps
}) => {
	return (
		<Field name={name}>
			{({ field, meta }) => {
				const hasError = meta.touched && !!meta.error
				const errorMessage = hasError ? meta.error : ''
				return (
					<Box
						{...inputProps}
					>
						<FormHelperText 
						  id="outlined-weight-helper-text"
						  sx={{ fontWeight: 'bold' }}
						>
							{label}
						</FormHelperText>
						<TextField
  							multiline
							rows={rows}
							sx={{ 
								width: '100%',
								backgroundColor: 'white',
								borderColor: 'warning.main',
								"& .MuiInputBase-root": {
									minHeight: 100
								}
							}}
							InputProps={{
								style: {
								  backgroundColor: 'white',
								  borderColor: 'warning.main'
								}
							}}
							{...field}
							type={type}
							name={name}
							id={id}
							placeholder={placeholder}
							defaultValue={defaultValue}
							aria-invalid='true'
							error={hasError}
						/>

						{hasError ? (
							<FormHelperText 
								id="outlined-weight-helper-text"
								sx={{
									color: 'warning.main',
									fontSize: 14,
									letterSpacing: 0.5 
								}}
							>
								{errorMessage}
							</FormHelperText>
							)
							: (
								<FormHelperText 
								id="outlined-weight-helper-text"
								sx={{
									color: grey[500],
									fontSize: 14,
									letterSpacing: 0.5
								}}
								>
									{helperText}
								</FormHelperText>
							)
						}
					</Box>
				)
			}}
		</Field>
	)
}