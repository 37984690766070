/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';

function InfoInput ({
	name,
	placeholder,
	id,
	label,
	...inputProps
}){
	
	const [anchorEl, setAnchorEl] = useState(null);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);
  	const pid = open ? 'simple-popover' : undefined;
	const endAdorment = () => (
		<InputAdornment 
			position="end" 
			className="popover_class"
		>
			<InfoOutlinedIcon
				aria-describedby={pid} variant="contained" onClick={handleClick}
				sx={{
					fontSize:"25px!important"
				}}
			/>
			<Popover
				id={pid}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				sx={{
					marginTop: -1,
				}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				>
				<Box
					component="img"
					sx={{
						marginTop: 2,
						width: '100%'
					}}
					alt="information"
					src={require('../../assets/images/information.png')}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null;
						currentTarget.src=require('../../assets/images/default_image.png');
					}}
				/>
				<Typography 
					sx={{
						textAlign: 'center',
						width: '100%',
						color: grey[600],
						letterSpacing: 0.7,
						fontSize: 15,
						paddingBottom: 3
					}}
				>
					You can find the Item ID on the Packaging <br/> Slip that came in attached to the box
				</Typography>
			</Popover>
		</InputAdornment>
	)
	
	return (
		<Field name={name}>
			{({ field, meta }) => {
				const hasError = meta.touched && !!meta.error
				const errorMessage = hasError ? meta.error : ''
				return (
					<Box
						{...inputProps}
					>
						<FormHelperText 
						  id="outlined-weight-helper-text"
						  sx={{ fontWeight: 'bold' }}
						>
							{label}
						</FormHelperText>
						<TextField
							sx={{ 
								width: '100%',
								backgroundColor: 'white',
								borderColor: 'warning.main',
								"& .MuiInputBase-root": {
									height: 50
								}
							}}
							InputProps={{
								endAdornment: endAdorment(hasError, meta.touched),
								style: {
								  backgroundColor: 'white',
								  borderColor: 'warning.main'
								}
							}}
							{...field}
							name={name}
							id={id}
							placeholder={placeholder}
							aria-invalid='true'
							error={hasError}
						/>

						{hasError && (
							<FormHelperText 
								id="outlined-weight-helper-text"
								sx={{
									color: 'warning.main'
								}}
							>
								{errorMessage}
							</FormHelperText>
						)}
					</Box>
				)
			}}
		</Field>
	)
}

InfoInput.propTypes = {
	name: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	id: PropTypes.string,
};

InfoInput.defaultProps = {
	placeholder: '',
	label: '',
	id: '',
};
export default InfoInput;
/* eslint-enable */