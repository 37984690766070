export const DTC_BRANDS = ["mikasa", "pfaltzgraff", "fitzfloyd", "planetbox", "builtny", "fredfriends", "taylorusa", "rabbitwine", "chefn", "freespicerefills", "yearday", "swell"];
export const COSTCO_BRANDS = ["mikasacostco", "fitzfloydcostco", "rabbitwinecostco"];
export const HEADER = {
    mikasa: {
        brand: "Mikasa",
        homepage: "https://www.mikasa.com/",
        logo: "https://cdn.shopify.com/s/files/1/0325/5746/5736/files/Mikasa-Blue-Primary.png?v=1659639093",
        faqs: "https://www.mikasa.com/pages/faqs",
        termsOfUse: "https://www.mikasa.com/pages/terms-of-use",
        privacyPolicy: "https://www.mikasa.com/pages/privacy-policy",
        californiaPrivacyPolicy: "https://www.mikasa.com/pages/privacy-policy-california",
        contactUs: "https://www.mikasa.com/pages/contact-us",
        email: "service@mikasa.com"
    },
    pfaltzgraff: {
        brand: "Pfaltzgraff",
        homepage: "https://www.pfaltzgraff.com/",
        logo: "https://cdn.shopify.com/s/files/1/0324/1374/5285/files/Pfaltzgraff-logo2020.svg?v=1614704571",
        faqs: "https://www.pfaltzgraff.com/pages/general-faqs",
        termsOfUse: "https://www.pfaltzgraff.com/pages/terms-of-use",
        privacyPolicy: "https://www.pfaltzgraff.com/pages/privacy-policy",
        californiaPrivacyPolicy: "https://www.pfaltzgraff.com/pages/california-privacy-policy",
        contactUs: "https://www.pfaltzgraff.com/pages/contact-us",
        email: "service@pfaltzgraff.com"
    },
    fitzfloyd: {
        brand: "Fitz & Floyd",
        homepage: "https://www.fitzandfloyd.com/",
        logo: "https://cdn.shopify.com/s/files/1/0334/4285/7091/files/FitzWordMark-01.svg?v=1594932401",
        faqs: "https://www.fitzandfloyd.com/pages/faqs",
        termsOfUse: "https://www.fitzandfloyd.com/pages/terms-of-use",
        privacyPolicy: "https://www.fitzandfloyd.com/pages/privacy-policy",
        californiaPrivacyPolicy: "https://www.fitzandfloyd.com/pages/california-privacy-policy",
        contactUs: "https://www.fitzandfloyd.com/pages/contact-us",
        email: "service@fitzandfloyd.com"
    },
    planetbox: {
        brand: "PlanetBox",
        homepage: "https://www.planetbox.com/",
        logo: "https://cdn.shopify.com/s/files/1/0219/2354/files/PB-logo-primary-Teal_200x_2x_c8c9706b-420d-42c5-80fd-4ad4984aae3a_200x@2x.png?v=1641315376",
        faqs: "https://www.planetbox.com/apps/help-center",
        termsOfUse: "https://www.planetbox.com/pages/terms-of-use",
        privacyPolicy: "https://www.planetbox.com/pages/privacy-policy",
        californiaPrivacyPolicy: "https://www.planetbox.com/pages/california-privacy-notice",
        contactUs: "https://www.planetbox.com/pages/contact-us",
        email: "info@planetbox.com"
    },
    builtny: {
        brand: "BuiltNY",
        homepage: "https://www.builtny.com/",
        logo: "https://cdn.shopify.com/s/files/1/0306/0262/5157/files/BUILT_Logo_Red_On_White_200x_2x_4e5981a9-07e7-44a6-81ac-feb391d060a0_200x@2x.jpg?v=1641399878",
        faqs: "https://www.builtny.com/pages/faq",
        termsOfUse: "https://www.builtny.com/pages/terms-of-use",
        privacyPolicy: "https://www.builtny.com/pages/privacy-policy",
        californiaPrivacyPolicy: "https://www.builtny.com/pages/california-privacy-policy",
        contactUs: "https://www.builtny.com/pages/contact-us",
        email: "customerservice@builtny.com"
    },
    fredfriends: {
        brand: "Fred & Friends",
        homepage: "https://www.genuinefred.com/",
        logo: "https://cdn.shopify.com/s/files/1/0265/8936/4279/files/Logo-FredFriends.svg?v=1597433644",
        faqs: null,
        termsOfUse: "https://www.genuinefred.com/pages/terms-of-use",
        privacyPolicy: "https://www.genuinefred.com/pages/privacy-policy",
        californiaPrivacyPolicy: "https://www.genuinefred.com/pages/california-privacy-policy",
        contactUs: "https://www.genuinefred.com/pages/contact-us",
        email: "service@genuinefred.com"
    },
    taylorusa: {
        brand: "Taylor USA",
        homepage: "https://www.taylorusa.com/",
        logo: "https://cdn.shopify.com/s/files/1/0324/1433/5109/files/Taylor_Logos-01.svg?v=1595363938",
        faqs: null,
        termsOfUse: "https://www.taylorusa.com/pages/terms-of-use",
        privacyPolicy: "https://www.taylorusa.com/pages/privacy-policy",
        californiaPrivacyPolicy: "https://www.taylorusa.com/pages/california-privacy-policy",
        contactUs: "https://www.taylorusa.com/pages/contact-us",
        email: "service@taylorusa.com"
    },
    rabbitwine: {
        brand: "Rabbit Wine",
        homepage: "https://www.rabbitwine.com/",
        logo: "https://cdn.shopify.com/s/files/1/1334/8949/files/1_Logos-Rabbit_200x@2x.png?v=1586211514",
        faqs: "https://www.rabbitwine.com/pages/faqs",
        termsOfUse: "https://www.rabbitwine.com/pages/terms-of-use",
        privacyPolicy: "https://www.rabbitwine.com/pages/privacy-policy-1",
        californiaPrivacyPolicy: "https://www.rabbitwine.com/pages/ccpa-privacy-notice-for-california-residents",
        contactUs: "https://www.rabbitwine.com/pages/contact-us",
        email: "rabbitwine@lifetimebrands.com"
    },
    chefn: {
        brand: "Chef'n",
        homepage: "https://www.chefn.com/",
        logo: "https://cdn.shopify.com/s/files/1/0330/4013/8380/files/1_Logos-Chefn.svg?v=1588201987",
        faqs: "https://www.chefn.com/pages/faq",
        termsOfUse: "https://www.chefn.com/pages/terms-of-use",
        privacyPolicy: "https://www.chefn.com/pages/privacy-policy",
        californiaPrivacyPolicy: "https://www.chefn.com/pages/california-privacy-policy-1",
        contactUs: "https://www.chefn.com/pages/contact-us",
        email: "chefn@lifetimebrands.com"
    },
    freespicerefills: {
        brand: "Free Spice Refills",
        homepage: "https://freespicerefills.com/",
        logo: "https://cdn.shopify.com/s/files/1/0507/9485/6614/files/Primary.png?v=1607542680",
        faqs: null,
        termsOfUse: "https://freespicerefills.com/pages/terms-of-use",
        privacyPolicy: "https://freespicerefills.com/pages/privacy-policy",
        californiaPrivacyPolicy: "https://freespicerefills.com/pages/california-privacy-policy",
        contactUs: "https://freespicerefills.com/pages/contact-us",
        email: null
    },
    yearday: {
        brand: "Year & Day",
        homepage: "https://yearandday.com/",
        logo: "https://cdn.shopify.com/s/files/1/0582/2657/3483/files/BRAND_LOGO_500x.png?v=1631219742",
        faqs: "https://yearandday.com/pages/faq",
        termsOfUse: "https://yearandday.com/pages/terms-and-conditions",
        privacyPolicy: "https://yearandday.com/pages/privacy-policy",
        californiaPrivacyPolicy: "https://yearandday.com/pages/california-privacy-policy",
        contactUs: "https://yearandday.com/pages/contact",
        email: null
    },
    swell: {
        brand: "SWell",
        homepage: "https://www.swell.com/",
        logo: "https://d34kame2p3gj5k.cloudfront.net/media/uploads/2022/08/19131918/EPC-2616_MHP_Desktop.jpg",
        faqs: "https://www.swell.com/help/",
        termsOfUse: "https://www.swell.com/policies",
        privacyPolicy: "https://www.swell.com/privacy-policy/",
        californiaPrivacyPolicy: "https://www.swell.com/privacy-policy/",
        contactUs: "https://www.swell.com/help/#contact-us",
        email: "info@swell.com"
    },
    mikasacostco: {
        brand: "Mikasa Costco",
        homepage: "https://costco.mikasa.com/",
        logo: "https://cdn.shopify.com/s/files/1/0536/2073/6195/files/Primary.png?v=1612288488",
        faqs: "https://costco.mikasa.com/pages/faqs",
        termsOfUse: "https://costco.mikasa.com/pages/terms-of-use",
        privacyPolicy: "https://costco.mikasa.com/pages/privacy-policy",
        californiaPrivacyPolicy: "https://costco.mikasa.com/pages/privacy-policy-california",
        contactUs: "https://costco.mikasa.com/pages/contact-us",
        email: "membershelp@lifetimebrands.com"
    },
    fitzfloydcostco: {
        brand: "Fitz & Floyd Costco",
        homepage: "https://costco.fitzandfloyd.com/",
        logo: "https://cdn.shopify.com/s/files/1/0334/4285/7091/files/FitzWordMark-01.svg?v=1594932401",
        faqs: "https://costco.fitzandfloyd.com/pages/faqs",
        termsOfUse: "https://costco.fitzandfloyd.com/pages/terms-of-use",
        privacyPolicy: "https://costco.fitzandfloyd.com/pages/privacy-policy",
        californiaPrivacyPolicy: "https://costco.fitzandfloyd.com/pages/california-privacy-policy",
        contactUs: "https://costco.fitzandfloyd.com/pages/contact",
        email: "membershelp@lifetimebrands.com"
    },
    rabbitwinecostco: {
        brand: "Rabbitwine Costco",
        homepage: "https://costco.rabbitwine.com/",
        logo: "https://cdn.shopify.com/s/files/1/0599/3938/9595/files/Rabbit_Logo_de46db43-e61b-42fd-9686-bc5c2373293b.png?v=1632952981",
        faqs: "https://costco.rabbitwine.com/pages/faqs",
        termsOfUse: "https://costco.rabbitwine.com/pages/terms-of-use",
        privacyPolicy: "https://costco.rabbitwine.com/pages/privacy-policy-1",
        californiaPrivacyPolicy: "https://costco.rabbitwine.com/pages/ccpa-privacy-notice-for-california-residents",
        contactUs: "https://costco.rabbitwine.com/pages/contact-us",
        email: "membershelp@lifetimebrands.com"
    },
    wholesale: {
        brand: "Wholesale",
        homepage: "https://lifetimebrands.com/",
        logo: "https://lifetimebrands.com/wp-content/uploads/sites/6/2018/07/Lifetime-Brands-Logo-2.png",
        faqs: null,
        termsOfUse: null,
        privacyPolicy: "https://lifetimebrands.com/privacy-policy/",
        californiaPrivacyPolicy: null,
        contactUs: "https://lifetimebrands.com/customer-care/",
        email: "membershelp@lifetimebrands.com"
    },

};

export const RETAILERS = {
    "Amazon.com": "Amazon.com",
    "Bealls Department Stores": "Bealls Department Stores",
    "Bed Bath & Beyond": "Bed Bath & Beyond",
    "bedbathandbeyond.com": "bedbathandbeyond.com",
    "Belk": "Belk",
    "Costco": "Costco",
    "costco.com": "costco.com",
    "Dillard's": "Dillard's",
    "HomeGoods": "HomeGoods",
    "Hudson Bay Company": "Hudson Bay Company",
    "JC Penney": "JC Penney",
    "jcpenney.com": "jcpenney.com",
    "kohls.com": "kohls.com",
    "Macy's": "Macy's",
    "macys.com": "macys.com",
    "Neiman Marcus": "Neiman Marcus",
    "replacements.com": "replacements.com",
    "Ross-Simon": "Ross-Simon",
    "TJ Maxx": "TJ Maxx",
    "wayfair.com": "wayfair.com",
    "williamashley.com": "williamashley.com",
    "zola.com": "zola.com",
    "other": "Other"
}
export const COUNTRIES = {
    "usa": "USA",
    "canada": "Canada"
}
export const STATES = {
    "usa":  {
        'AL': 'Alabama', 
        'AK': 'Alaska', 
        'AZ': 'Arizona', 
        'AR': 'Arkansas', 
        'CA': 'California', 
        'CO': 'Colorado', 
        'CT': 'Connecticut', 
        'DE': 'Delaware', 
        'DC': 'Washington DC', 
        'FL': 'Florida', 
        'GA': 'Georgia', 
        'HI': 'Hawaii', 
        'ID': 'Idaho',
        'IL': 'Illinois',
        'IN': 'Indiana',
        'IA': 'Iowa',
        'KS': 'Kansas',
        'KY': 'Kentucky',
        'LA': 'Louisiana',
        'ME': 'Maine',
        'MD': 'Maryland',
        'MA': 'Massachusetts',
        'MI': 'Michigan',
        'MN': 'Minnesota',
        'MS': 'Mississippi',
        'MO': 'Missouri',
        'MT': 'Montana',
        'NE': 'Nebraska',
        'NV': 'Nevada',
        'NH': 'New Hampshire',
        'NJ': 'New Jersey',
        'NM': 'New Mexico',
        'NY': 'New York',
        'NC': 'North Carolina',
        'ND': 'North Dakota',
        'OH': 'Ohio',
        'OK': 'Oklahoma',
        'OR': 'Oregon',
        'PA': 'Pennsylvania',
        'PR': 'Puerto Rico',
        'RI': 'Rhode Island',
        'SC': 'South Carolina',
        'SD': 'South Dakota',
        'TN': 'Tennessee',
        'TX': 'Texas',
        'UT': 'Utah',
        'VT': 'Vermont',
        'VA': 'Virginia',
        'WA': 'Washington',
        'WV': 'West Virginia',
        'WI': 'Wisconsin',
        'WY': 'Wyoming'
    },
    "canada": {
        'AB': 'Alberta',
        'BC': 'British Columbia',
        'MB': 'Manitoba',
        'NB': 'New Brunswick',
        'NL': 'Newfoundland and Labrador',
        'NT': 'Northwest Territories',
        'NS': 'Nova Scotia',
        'NU': 'Nunavut',
        'ON': 'Ontario',
        'PE': 'Prince Edward Island',
        'QC': 'Quebec',
        'SK': 'Saskatchewan',
        'YT': 'Yukon'
    }
}

export const TITLES = {
    faqs: "Frequently Asked Questions",
    termsOfUse: "Terms & Conditions",
    privacyPolicy: "Privacy Policy",
    contactUs: "Contact Us"
};
