import { string, object } from 'yup';

const additionalInformationValidationSchema = object({
  additionalInformation: string()
    .nullable()
    .max(1000, "Maximum 1000 characters allowed"),
});

export {
  additionalInformationValidationSchema
}; 