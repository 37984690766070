
import React, {useState, useEffect, useCallback} from 'react';
import { useSelector, useDispatch } from "react-redux";
import Container from '@mui/material/Container';
import Input from '../../components/form/validateInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { grey } from '@mui/material/colors';
import FormHelperText from '@mui/material/FormHelperText';
import Subtitle from '../../components/subtitle';
import SubParagraph from '../../components/subParagraph';
import { changeIsGift, changeOrderNumber, changeEmail, changeZipCode, setEmailValidated, setZipcodeValidated, setOrderNumberValidated } from "../../slices/dtcSlice";
import { setItems } from "../../slices/appSlice";
import api from '../../app/api';

export const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, delay)
		return () => {
			clearTimeout(handler)
		}
	}, [delay, value])
	return debouncedValue
}

export default function StepOne() {
  const { brand, isGift, email, orderNumber, zipCode, isEmailFailed, isOrderNumberValidated, isEmailValidated, isZipCodeValidated } = useSelector((state) => state.dtc);
  const dispatch = useDispatch();

  const debouncedEmail = useDebounce(email, 500);
  const debouncedZipCode= useDebounce(zipCode, 500);
  const debouncedOrderNumber= useDebounce(orderNumber, 500);

  const [orderNumberChanged, setOrderNumberChanged] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [zipCodeChanged, setZipCodeChanged] = useState(false);

  const handleSetItems = useCallback((items) => {
    dispatch(setItems(items))
  }, [dispatch]);
  
  const handleSetValidatedField = useCallback((field, state) => {
    if(field === 'order_number') {
      dispatch(setOrderNumberValidated(state));
    } else if(field === 'email') {
      dispatch(setEmailValidated(state));
    } else if(field === 'zipcode') {
      dispatch(setZipcodeValidated(state));
    }
  }, [dispatch]);

  const apiValidation = useCallback(async (value) => {
    return await new Promise((resolve, reject) => {
      let payload = { order_number: orderNumber, zipcode: zipCode, is_a_gift: isGift, email : email };
      
      api.post('/validate', payload)
      .then(res => {
        let errors_array = res.data?.errors ? Object.keys(res.data?.errors) : [];
        if(errors_array.length < 3) {
          if(res.data.items) {
            handleSetItems(res.data.items.map(item => ({...item, piecesDamaged: 0, problemText: "", images: [], imageKeys: [], isSelected: false})));
          } 
          if (!errors_array.includes("order_number")) {
            handleSetValidatedField("order_number", true);
          } else {
            handleSetValidatedField("order_number", false);
          }
          if (!errors_array.includes("email") || isGift) {
            handleSetValidatedField("email", true);
          } else {
            handleSetValidatedField("email", false);
          }
          if (!errors_array.includes("zipcode")) {
            handleSetValidatedField("zipcode", true);
          } else {
            handleSetValidatedField("zipcode", false);
          }
          resolve(!errors_array.includes(value));
        }
        else {
          handleSetValidatedField(value, false);
          resolve(false);
        }
      }).catch((e)=> {
        let errors_array = e.response?.data?.errors ? Object.keys(e.response.data.errors) : [];
        if (!errors_array.includes("order_number")) {
          handleSetValidatedField("order_number", true);
        } else {
          handleSetValidatedField("order_number", false);
        }
        if (!errors_array.includes("email") || isGift) {
          handleSetValidatedField("email", true);
        } else {
          handleSetValidatedField("email", false);
        }
        if (!errors_array.includes("zipcode")) {
          handleSetValidatedField("zipcode", true);
        } else {
          handleSetValidatedField("zipcode", false);
        }
        handleSetValidatedField(value, (!errors_array.includes(value)));
        resolve(!errors_array.includes(value)) 
      });
    })
  }, [email, handleSetItems, handleSetValidatedField, isGift, orderNumber, zipCode]);

  useEffect(() => {
    debouncedOrderNumber && apiValidation("order_number");
  }, [debouncedOrderNumber, apiValidation]);
  useEffect(() => {
    debouncedEmail && apiValidation("email");
  }, [debouncedEmail, apiValidation]);
  useEffect(() => {
    debouncedZipCode && apiValidation("zipcode");
  }, [debouncedZipCode, apiValidation]);

  const handleChange = (target) => {
    if(target.name === "isGift") {
      dispatch(changeIsGift(target.checked));
    } else if (target.name === "orderNumber") {
      setOrderNumberChanged(true);
      dispatch(changeOrderNumber(target.value));
    } else if (target.name === "email") {
      setEmailChanged(true);
      dispatch(changeEmail(target.value));
    } else if (target.name === "zipCode") {
      setZipCodeChanged(true);
      dispatch(changeZipCode(target.value));
    } 
  }

  return (
    <Container 
      maxWidth="md"
      sx={{
        px: 0
      }}
    >
      <Subtitle>
        Enter your web order number
      </Subtitle>
      <SubParagraph align="justify">
        We apologize that your {brand} item(s) were damaged during shipping and
        our objective is to send you replacement items without delay. To accomplish
        this in the most expedient manner please provide the following information 
        below and you will be guided through the next steps to tell us about your
        damaged item(s). It will be helpful to have you packing slip available.
      </SubParagraph>
      <Container 
        maxWidth="xs"
        sx={{
          marginTop: 7,
          px: 0
        }}
      >
        <FormControlLabel 
							sx={{
								color: grey[800]
							}}
							control={
								<Checkbox
									sx={{
										color: grey[400],
										background: "white",
										transform: "scale(0.75)",
										padding: 0,
									}}
									name="isGift"
									aria-invalid='true'
                  onChange={e => {
                    handleChange(e.target);
                  }}
								/>} 
								label="If this was a gift, click here" 
						/>
            <Input
              label="ORDER NUMBER"
              name="orderNumber"
              placeholder="123456..."
              style={{marginTop: "24px"}}
              validated = {isOrderNumberValidated}
              touched = {orderNumberChanged}
              errorMessage = "Order number is invalid. A valid order number may contain letters, numbers, spaces, and certain symbols (-_./&#)"
              onChange={e => {
                handleChange(e.target);
              }}/>
            {(isOrderNumberValidated && isGift ) && <Input 
              label="EMAIL"
              name="email"
              placeholder="johndoe@mail.com"
              style={{marginTop: "24px"}}
              validated = {isEmailValidated}
              touched = {emailChanged}
              errorMessage = ""
              onChange={e => {
                handleChange(e.target);
              }}
              /> 
            }
            {(isOrderNumberValidated && !isGift ) && <Input 
              label="EMAIL"
              name="email"
              placeholder="johndoe@mail.com"
              style={{marginTop: "24px"}}
              validated = {isEmailValidated}
              touched = {emailChanged}
              errorMessage = "Email address does not match with the given order number"
              onChange={e => {
                handleChange(e.target);
              }}
              /> 
            }
            {
              isEmailFailed && <FormHelperText
                  id="outlined-weight-helper-text"
                  sx={{
                      color: 'warning.main',
                      marginTop: '25px'
                  }}
              >
                  Email validation failed, please continue validation using zip code.
              </FormHelperText>
            }
            {isOrderNumberValidated && <Input
              label="ZIP CODE"
              name="zipCode"
              placeholder="1234..."
              style={{marginTop: "24px", maxWidth: "140px"}}
              validated = {isZipCodeValidated}
              touched = {zipCodeChanged}
              errorMessage = "Zip code does not match with the given order number"
              onChange={e => {
                handleChange(e.target);
              }}/>
            }
      </Container>
    </Container>
  );
}
/* eslint-enable */