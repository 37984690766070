
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { setActiveStep, setLatestStep, completeSubmission } from "../slices/appSlice";
import { addItem } from "../slices/appSlice";
import { toaster } from './toaster';

var folder = 'test/';
var hostname = window.location.hostname;
if (hostname === 'replacements.lifetimebrands.com') {
  folder = 'production/';
}

export default function StepButtons() {
  const dispatch = useDispatch();
  const { steps, activeStep, latestStep, loading, isWholesale, isDtc, isCostco, brand, items, additionalInformation } = useSelector((state) => state.app);
  const dtcState = useSelector((state) => state.dtc);
  const wholesaleState = useSelector((state) => state.wholesale);

  const [ addItemButton, setAddItemButton ] = useState(false);
  const [ isCompleteSubmission, setIsCompleteSubmission ] = useState(activeStep === steps.length - 1);
  const itemAction =  (isWholesale || isCostco) ? 'added' : 'selected';

  const canGoBack = (isWholesale || isCostco) ? true : (items.length > 0 && activeStep > 1);

  useEffect(() => {
    setIsCompleteSubmission(activeStep === steps.length - 1)
  }, [activeStep, steps]);
  useEffect(() => {
    setAddItemButton((isWholesale || isCostco) && activeStep === 1 && items.length === 0)
  }, [isWholesale, activeStep, items, isCostco]);

  const handleNext = () => {
    let canProceed = true;
    if(activeStep === 1) {
      const anyWithoutPiecesDamaged = items.some(item => item.isSelected && (item.piecesDamaged === 0 || item.piecesDamaged === ''));
      const anyWithoutProblemText = items.some(item => item.isSelected && (item.problemText.length < 4 || item.problemText.length > 1000));
      const anyWithoutItemId = items.some(item => item.isSelected && (item.item_id.length < 4 || item.item_id.length > 15));
      if(anyWithoutPiecesDamaged) {
        canProceed = false;
        toaster(`You have ${itemAction} an item with 0 quantity. Please either unselect the item or provide the quantity of damaged pieces.`);
      }
      if(anyWithoutProblemText) {
        canProceed = false;
        toaster(`You have ${itemAction} an item with no description provided. Please either unselect the item or provide the problem with the items.`);
      }
      if(anyWithoutItemId) {
        canProceed = false;
        toaster(`You have ${itemAction} an item with no item id provided. Please either unselect the item or provide the item id.`);
      }
    }
    if(activeStep === 2) {
      const anySelectedItemWithoutImage = items.some(item => item.isSelected && (item.piecesDamaged !== 0 || item.piecesDamaged !== '') && item.images.length < 1);
      if(anySelectedItemWithoutImage) {
        canProceed = false;
        toaster('Please provide at least one image for each selected item.');
      }
    }
    if(isCompleteSubmission && !(latestStep <= activeStep) && canProceed) {
      if(isDtc) {
        const submissionData = {
          test_mode: false,
          is_a_gift: dtcState.isGift,
          order_number: dtcState.orderNumber,
          replacements_url: window.location.href,
          email: dtcState.email,
          zipcode: dtcState.zipCode,
          items:
            items
            .filter(item => item.isSelected && (item.piecesDamaged !== 0 || item.piecesDamaged !== '') && item.images.length > 0)
            .map(item => ({ item_number: item.item_id, pieces_damaged: parseInt(item.piecesDamaged), images: item.imageKeys.map(key => folder+key), problem: item.problemText}))
          ,
          additional_information: additionalInformation,
        }
        dispatch(completeSubmission(submissionData));
      } else if(isWholesale) {
        const data = new FormData();
        data.append('items', 
          JSON.stringify(
            items
            .filter(item => 
              item.isSelected && 
              (item.piecesDamaged !== 0 || item.piecesDamaged !== '') && 
              item.images.length > 0 &&
              item.item_id.length >= 5)
            .map(item => ({ itemID: item.item_id, images: item.imageKeys, problemText: item.problemText, piecesDamaged: item.piecesDamaged}))
          )
        );
        data.append('additionalInformation', additionalInformation);
        wholesaleState.retailerSelected !== 'other' ? 
          data.append('retailer', wholesaleState.retailerSelected) :
          data.append('retailer', wholesaleState.retailerName);
        data.append('additionalInformation', additionalInformation);
        data.append('personalInformation', JSON.stringify(wholesaleState.personalInformation));
        dispatch(completeSubmission(data));
      } else if(isCostco){
        const data = new FormData();
        data.append('items', 
          JSON.stringify(
            items
            .filter(item => 
              item.isSelected && 
              (item.piecesDamaged !== 0 || item.piecesDamaged !== '') && 
              item.images.length > 0 &&
              item.item_id.length >= 5)
            .map(item => ({ itemID: item.item_id, images: item.imageKeys, problemText: item.problemText, piecesDamaged: item.piecesDamaged}))
          )
        );
        wholesaleState.retailerSelected !== 'other' ? 
          data.append('retailer', wholesaleState.retailerSelected) :
          data.append('retailer', wholesaleState.retailerName);
        data.append('additionalInformation', additionalInformation);
        data.append('personalInformation', JSON.stringify(wholesaleState.personalInformation));
        dispatch(completeSubmission(data));
      }
    } else if(!(latestStep <= activeStep) && canProceed) {
      dispatch(setActiveStep(activeStep + 1));
    }
  };

  const handleBack = () => {
    if(canGoBack) {
      dispatch(setActiveStep(activeStep - 1));
    }
  };

  const handleAddItem = () => {
    dispatch(addItem());
  }

  useEffect(() => {
    if(isWholesale || isCostco) {
      const canProceed = (isWholesale || isCostco)? 
        (wholesaleState.purchaseDate &&
        ((wholesaleState.retailerSelected && wholesaleState.retailerSelected !== 'other') ||
        (wholesaleState.retailerName && wholesaleState.retailerName.length >= 5 && wholesaleState.retailerName.length <= 100)) &&
        wholesaleState.proofOfPurchase.length > 0) :
        wholesaleState.proofOfPurchase.length > 0;
      if(canProceed) {
        dispatch(setLatestStep(1));
        const anySelected = items.some(item => item.isSelected && item.piecesDamaged > 0 && item.problemText.length >= 4 && item.problemText.length <= 1000 && item.item_id.length >= 4);
        if(anySelected) {
          dispatch(setLatestStep(2));
          const allImageUploaded = items.filter(item => item.isSelected && item.piecesDamaged > 0).every(item => item.images.length > 0);
          if(allImageUploaded) {
            dispatch(setLatestStep(4));
            const personalInformationProvided =
              wholesaleState.personalInformation.name.length > 0 && 
              wholesaleState.personalInformation.email.length > 0 &&
              wholesaleState.personalInformation.addressOne.length > 0 &&
              wholesaleState.personalInformation.country.length > 0 &&
              wholesaleState.personalInformation.city.length > 0 &&
              wholesaleState.personalInformation.state.length > 0 &&
              wholesaleState.personalInformation.zipCode.length > 0
            if(personalInformationProvided) {
              dispatch(setLatestStep(5));
            }
          } else {
            dispatch(setLatestStep(2));
          }
        } else {
          dispatch(setLatestStep(1))
        }
      } else {
        dispatch(setLatestStep(0))
      }
    } else {
      if(items?.length > 0) {
        dispatch(setLatestStep(1));
        const anySelected = items.some(item => item.isSelected && item.piecesDamaged > 0 && item.problemText.length >= 4 && item.problemText.length <= 1000);
        if(anySelected) {
          dispatch(setLatestStep(2));
          const allImageUploaded = items.filter(item => item.isSelected && item.piecesDamaged > 0).every(item => item.images.length > 0);
          if(allImageUploaded) {
            dispatch(setLatestStep(4));
          } else {
            dispatch(setLatestStep(2));
          }
        } else {
          dispatch(setLatestStep(1))
        }
      }
    }
  }, [items, dispatch, brand, isWholesale, wholesaleState, isCostco]);
  
  return (
    activeStep !== steps.length && (
        <Container  
          maxWidth="lg"
          sx={{
            px: 0
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              pt: 2, 
              mt:3 }}>
              {
                activeStep !== 0 && 
                  <Button
                    color="inherit"
                    disabled={!canGoBack || activeStep === 0}
                    onClick={handleBack}
                    variant="outlined"
                    size="large"
                    sx={{
                      textAlign:"center",
                      fontWeight: 700,
                      fontSize: 18,
                      paddingX: 8,
                      paddingY: "10px",
                      margin: "auto",
                      marginY: 1,
                      borderWidth: 3,
                      borderStyle: 'solid',
                      borderColor: "borderPrimary",
                      color: "borderPrimary",
                      borderRadius: 50
                    }}
                  >
                    Back
                  </Button>
            }
              {addItemButton ?
                <Button 
                  onClick={handleAddItem}
                  variant="contained" 
                  size="large"
                  sx={{
                    textAlign:"center",
                    fontWeight: 700,
                    fontSize: 18,
                    paddingX: 8,
                    paddingY: "10px",
                    margin: "auto",
                    marginY: 1,
                    background: 'linear-gradient(to right, #4757D6, #8B9BFA)',
                    borderRadius: 50
                  }}
                >
                  + Add Item
                </Button> :
                <LoadingButton
                  onClick={handleNext}
                  loading={loading}
                  variant="contained" 
                  size="large"
                  sx={{
                    textAlign:"center",
                    fontWeight: 700,
                    fontSize: 18,
                    paddingX: 8,
                    paddingY: "10px",
                    margin: "auto",
                    marginY: 1,
                    background: 'linear-gradient(to right, #4757D6, #8B9BFA)',
                    borderRadius: 50
                  }}
                >
                  {isCompleteSubmission ? 'Complete your submission' : "Next"}
                </LoadingButton>
              }
          </Box>
        </Container>
      )
  );
}
