import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';

export default function Input ({
	name,
	placeholder,
	id,
	type,
	label,
	disabled,
	value,
	...inputProps
}) {
	function endAdorment (hasError, touched) {
		let fragment;
		if(hasError && touched) {
			fragment = <InputAdornment position="end">
							<CloseIcon
								sx={{
									color: 'warning.main',
									fontSize:"20px!important"
								}}
							/>
						</InputAdornment>;
		} else if(!hasError && touched) {
			fragment = <InputAdornment position="end">
					  <DoneIcon 
						sx={{
							color: 'submissionSuccess',
							fontSize:"20px!important"
						  }}
					  />
					</InputAdornment>;
		} else {
			fragment = <div/>;
		}
		return fragment;
	}
	return (
		<Field name={name}>
			{({ field, meta }) => {
				const hasError = meta.touched && !!meta.error
				const errorMessage = hasError ? meta.error : ''
				return (
					<Box
						{...inputProps}
					>
						<FormHelperText 
						  id="outlined-weight-helper-text"
						  sx={{ fontWeight: 'bold' }}
						>
							{label}
						</FormHelperText>
						<TextField
							sx={{ 
								width: '100%',
								backgroundColor: 'white',
								borderColor: 'warning.main',
								"& .MuiInputBase-root": {
									height: 50
								}
							}}
							disabled={disabled}
							InputProps={{
								endAdornment: endAdorment(hasError, meta.touched),
								style: {
								  backgroundColor: 'white',
								  borderColor: 'warning.main'
								}
							}}
							{...field}
							type={type}
							name={name}
							value={value}
							id={id}
							placeholder={placeholder}
							aria-invalid='true'
							error={hasError}
						/>

						{hasError && (
							<FormHelperText 
								id="outlined-weight-helper-text"
								sx={{
									color: 'warning.main'
								}}
							>
								{errorMessage}
							</FormHelperText>
						)}
					</Box>
				)
			}}
		</Field>
	)
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.number
  ]),
};

Input.defaultProps = {
  placeholder: '',
  label: '',
  id: '',
  type: 'text',
  value: '',
};