import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const personalInformation = {
  name: '',
  email: '',
  phone: '',
  addressOne: '',
  addressTwo: '',
  country: 'usa',
  city: '',
  state: '',
  zipCode: '',
}

const initialState = {
  status: 'idle',
  purchaseDate: dayjs(new Date()).toString(),
  isOtherSelected: false,
  retailerSelected: '',
  retailerName: '',
  proofOfPurchase: [],
  proofOfPurchaseKeys: [],
  additionalInformation: "",
  personalInformation: personalInformation,
  loading: false,
  error: null,
};


export const wholesaleSlice = createSlice({
  name: 'wholesale',
  initialState,
  reducers: {
    setPurchaseDate: (state, { payload }) => {
      state.purchaseDate = payload;
    },
    setRetailerSelected: (state, { payload }) => {
      state.retailerName = '';
      if(payload === "other") {
        state.isOtherSelected = true;
      } else {
        state.isOtherSelected = false;
      }
      state.retailerSelected = payload;
    },
    setPersonalInformation: (state, { payload }) => {
      state.personalInformation = payload;
    },
    setRetailerName: (state, { payload }) => {
      state.retailerName = payload;
    },
    uploadProofOfPurchase: (state, { payload }) => {
      state.proofOfPurchase = [...payload.images];
      state.proofOfPurchaseKeys = [...payload.imageKeys];
    },
  },
});

export const { 
  setPurchaseDate,
  setRetailerSelected,
  setRetailerName,
  uploadProofOfPurchase,
  setPersonalInformation
 } = wholesaleSlice.actions;

export default wholesaleSlice.reducer;
