import React from 'react';
import Header from '../components/header';
import { Box, Typography } from '@mui/material';
import Container from '@mui/material/Container';

export default function noPage() {
  return (
    <>
      <Header />
      <Container 
        maxWidth="md"
        sx={{
          px: 0
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '50vh'
          }}
        >
          <Typography 
            variant="h1" 
            sx={{ fontWeight: 'bold', padding: 1}} 
            color="primary">
            404
          </Typography>
          <Typography variant="h6" style={{ color: 'primary' }}>
            The page you’re looking for doesn’t exist.
          </Typography>
        </Box>
      </Container>
    </>
  );
}
