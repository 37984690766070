import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import Container from '@mui/material/Container';
import { Formik, Form } from 'formik';
import Subtitle from './subtitle';
import SubParagraph from './subParagraph';
import { FormText } from './form/textarea';
import { changeAdditionalInformation } from "../slices/appSlice";
import { additionalInformationValidationSchema } from "../validations/additionalInfoValidations";

export default function AdditionalInfo() {
  const { additionalInformation } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(changeAdditionalInformation(event.target.value));
  }

  return (
    <Container 
      maxWidth="md"
      sx={{
        px: 0
      }}
    >
      <Subtitle>
        Provide Additional information
      </Subtitle>
      <SubParagraph align="justify">
        Please provide any additional information to help us 
        better understand the damage you evaluated on the 
        piece or pieces and how we can better service you as 
        our customer as we work to fulfill your replacement 
        item(s).
      </SubParagraph>
      <Formik 
        initialValues = {
          {
            additionalInformation: additionalInformation,
          }
        }
        validateOnMount = {false}
        validationSchema = {additionalInformationValidationSchema}
      >
        <Form
          style= {{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "65px"
          }}
        >
          <FormText 
            label="ADDITIONAL INFORMATION (OPTIONAL)" 
            name="additionalInformation" 
            placeholder="Additional information goes here..." 
            style={{
              width: "500px"
            }}
            onChange={handleChange}
            rows="7"
            helperText=""/>    
        </Form>  
      </Formik>
    </Container>
  );
}
