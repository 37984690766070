import { string, object } from 'yup';

const personalInfoValidationSchema = object({
    name: string()
        .nullable()
        .required("Please enter name"),
    email: string()
        .nullable()
        .email('Should be a valid email address')
        .required("Please enter email"),
    address_one: string()
        .nullable()
        .required("Please enter an address"),
    country: string()
        .nullable()
        .required("Please enter country"),
    city: string()
        .nullable()
        .required("Please enter city"),
    state_province: string()
        .nullable()
        .required("Please enter state/province"),
    zipCode: string()
        .nullable()
        .required("Please enter zip code"),
  });

export {
    personalInfoValidationSchema
};
