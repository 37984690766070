
import { ThemeProvider } from "@mui/material";
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#6979F8'
    },
    secondary: {
      main: '#f7fafc'
    },
    warning: {
      main: '#ff0000'
    },
    paragraph: '#343434',
    submissionSuccess: '#45CEA0',
    submissionFail: '#FF536D',
    textPrimary: '#6979F8',
    borderPrimary: '#6979F8',
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  shape: {
    borderRadius: 4
  },
});

export default function ProductReplacementTheme({children}) {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
};


