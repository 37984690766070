import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const File = ({ name, display, label, ...rest }) => {
  return (
    <Box sx={{display: display}}>
      <input type='file' id={name} name={name} {...rest}/>
    </Box>
  );
};

File.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

File.defaultProps = {
  label: '',
};

export default File;
