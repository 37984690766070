import { Field } from 'formik';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

export const DatePicker = ({
	name,
	placeholder,
	type,
	id,
	label,
	value,
	onChange,
	...inputProps
}) => {
	return (
		<Field name={name}>
			{({ field, meta }) => {
				const hasError = meta.touched && !!meta.error
				const errorMessage = hasError ? meta.error : ''
				return (
					<Box
						{...inputProps}
					>
						<FormHelperText 
						  id="outlined-weight-helper-text"
						  sx={{ fontWeight: 'bold' }}
						>
							{label}
						</FormHelperText>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Stack spacing={3}>
								<DesktopDatePicker
									inputFormat="MM/DD/YYYY"
									value={value}
									onChange={onChange}
									maxDate = {new Date()}
									renderInput={(params) => <TextField 
													sx={{ 
														width: '100%',
														backgroundColor: 'white',
														borderColor: 'warning.main',
														"& .MuiInputBase-root": {
															height: 50
														}
													}}
													{...params} />
												}
								/>
							</Stack>
						</LocalizationProvider>
						{hasError && (
							<FormHelperText 
								id="outlined-weight-helper-text"
								sx={{
									color: 'warning.main'
								}}
							>
								{errorMessage}
							</FormHelperText>
						)}
					</Box>
				)
			}}
		</Field>
	)
}