import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Container from '@mui/material/Container';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import { setAppTypeValues } from "../slices/appSlice";
import { DTC_BRANDS, COSTCO_BRANDS, HEADER, TITLES } from '../constants';

const drawerWidth = 260;

export default function Header(props) {
  const dispatch = useDispatch();
  const { brand } = useParams();
  const [currentBrand, setCurrentBrand] = useState({});
  const [navItems, setNavItems] = useState([]);

  const isDtc= (DTC_BRANDS.includes(brand));
  const isCostco= (COSTCO_BRANDS.includes(brand));
  const isWholesale= !isDtc && !isCostco;

  useEffect(() => {
    dispatch(setAppTypeValues({isDtc:isDtc, isCostco:isCostco, isWholesale:isWholesale, brand:HEADER[brand]}))
  }, [dispatch, isDtc, isCostco, isWholesale, brand]);

  useEffect(() => {
    let links = [];
    Object.keys(TITLES).forEach((title) => {
      if (currentBrand[title]) {
        links.push({
          title: TITLES[title],
          href: currentBrand[title]
        });
      }
    });
    setNavItems(links);
  }, [currentBrand]);
  useEffect(() => {
    if(isDtc || isCostco){
      setCurrentBrand(HEADER[brand]);
    } else {
      setCurrentBrand(HEADER["wholesale"])
    }
  }, [brand, isDtc, isCostco]);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Box
        component="div"
        sx={{ my: 2 }}
      >
        <Box
          component="img"
          sx={{
            width: 300,
            maxWidth: { xs: 150, md: 200 }
          }}
          alt={`${currentBrand['currentBrand']} logo`}
          src={currentBrand['logo']}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src=require('../assets/images/default_image.png');
          }}
        />
      </Box>
      <Divider />
      <List>
        {navItems && navItems.map((item) => (
          <ListItem key={item["title"]} disablePadding sx={{ justifyContent: 'center', padding: 1 }} >
            <Link 
              href={item["href"]}
              sx={{ fontWeight: 'bold', padding: 1}} 
              color="primary"
              underline="none"
              target="_blank"
              rel="noreferrer">
                {item["title"]}
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" sx={{ background: '#ffffff' }}>
        <Container maxWidth="lg">
          <Toolbar style={{ padding: 0 }}>
            <Box
              component="div"
              sx={{ flexGrow: 1 }}
            >
              <Link 
                href={currentBrand["homepage"]}
                sx={{ fontWeight: 'bold', padding: 1}} 
                color="primary"
                underline="none"
                target="_blank"
                rel="noreferrer">
                  <Box
                    component="img"
                    sx={{
                      width: 300,
                      maxWidth: { xs: 150, md: 200 },
                      paddingTop: 2,
                      paddingBottom: 2,

                    }}
                    alt={`${currentBrand['currentBrand']} logo`}
                    src={currentBrand['logo']}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src=require('../assets/images/default_image.png');
                    }}
                  />
              </Link>
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              {navItems && navItems.map((item) => (
                <Link 
                  href={item["href"]} 
                  key={item["title"]} 
                  sx={{ fontWeight: 'bold', padding: 1, fontSize: 14}} 
                  color="primary"
                  underline="none"
                  target="_blank"
                  rel="noreferrer">
                    {item["title"]}
                </Link>
              ))}
            </Box>

            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}