import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export default function SubParagraph({children, ...props}) {
  return (
    <Typography 
      variant="body1"
      fontSize={18}
      color="paragraph"
      sx={{ letterSpacing: 1 }}
      {...props}
    >
      {children}
    </Typography>
  );
}

SubParagraph.propTypes = {
  children: PropTypes.any,
  align: PropTypes.string
};

SubParagraph.defaultProps = {
  children: "",
  align: "justify"
};