import { Field } from 'formik';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export const Dropdown = ({
	name,
	placeholder,
	type,
	id,
	label,
	defaultValue,
	options,
	value,
	onChange,
	...inputProps
}) => {
	return (
		<Field name={name}>
			{({ field, meta }) => {
				const hasError = meta.touched && !!meta.error
				const errorMessage = hasError ? meta.error : ''
				return (
					<Box
						{...inputProps}
					>
						<FormHelperText 
						  id="outlined-weight-helper-text"
						  sx={{ fontWeight: 'bold' }}
						>
							{label}
						</FormHelperText>
						<Select
							id="retailer-select"
							value={value}
							name={name}
							onChange={onChange}
							sx={{ 
								width: '100%',
								backgroundColor: 'white',
								borderColor: 'warning.main',
								"& .MuiInputBase-root": {
									height: 50
								}
							}}
							inputProps={{ 'aria-label': 'Without label' }}
						>
							{Object.keys(options).map(key => <MenuItem value={key} key={key}>{options[key]}</MenuItem>)}
						</Select>

						{hasError && (
							<FormHelperText 
								id="outlined-weight-helper-text"
								sx={{
									color: 'warning.main'
								}}
							>
								{errorMessage}
							</FormHelperText>
						)}
					</Box>
				)
			}}
		</Field>
	)
}