/* eslint-disable */
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';
import { Formik } from 'formik';
import Input from './form/input';
import InfoInput from './form/infoInput';
import { FormText } from './form/textarea';
import { addItem, setItemId, deleteItem, setProblemText, setDamagedPieces } from "../slices/appSlice";
import { itemValidationSchema } from "../validations/itemValitdations";

function TableTitle({children, minWidth, maxWidth, display}){
  return(
  <TableCell 
      sx={{
          color: grey[500],
          fontWeight: 700,
          fontSize: 13,
          minWidth,
          maxWidth,
          display
      }}
  >
    {children}
  </TableCell>
)};

function TableItem({children, minWidth, maxWidth, display, px}){
  return(
  <TableCell 
      style={{ verticalAlign: 'top' }}
      sx={{
          fontWeight: 700,
          fontSize: 14,
          color: "paragraph",
          minWidth,
          maxWidth,
          display,
          border: 0,
          px
      }}
  >
    {children}
  </TableCell>
)};
export default function DamagedItemsList() {
  const { items } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const handleAddItem = () => {
    dispatch(addItem());
  }
  const handleDeleteItem = (itemIndex) => {
    dispatch(deleteItem(itemIndex));
  }
  const handleItemIdChange = (value, index) => {
    dispatch(setItemId({
      item_id: value,
      index: index
    }));
  };
  const handleProblemTextChange = (value, index) => {
    dispatch(setProblemText({
      problemText: value,
      index: index
    }));
  };
  const handleDamagedPiecesChange = (value, index) => {
    dispatch(setDamagedPieces({
      piecesDamaged: value,
      index: index
    }));
  };

  return (
     <TableContainer sx={{ mt: 7 }}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableTitle>#</TableTitle>
            <TableTitle>ITEM ID</TableTitle>
            <TableTitle>PIECES DAMAGED</TableTitle>
            <TableTitle
              display={{ xs: 'none', md: 'table-cell' }}
            >
              DESCRIPTION &amp; ITEM PATTERN
            </TableTitle>
            <TableTitle/>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row, index) => (
            <Formik 
              initialValues = {
                {
                  piecesDamaged: row.piecesDamaged,
                  problemText: row.problemText,
                  item_id: row.item_id
                }
              }
              validateOnMount = {false}
              validationSchema = {itemValidationSchema}
              key = {row.uniqueItemId}
            >
              {()=>
                <>
                  <TableRow>
                    <TableItem component="th" scope="row">{index + 1}</TableItem>
                    <TableItem minWidth={120}>
                      <InfoInput 
                        name="item_id" 
                        defaultValue={row.item_id}
                        onChange={(e) => {
                          handleItemIdChange(e.target.value, index);
                        }}
                      />
                    </TableItem>
                    <TableItem 
                      maxWidth={140}
                      px={0}
                    >
                      <Input 
                        name="piecesDamaged"
                        value={row.piecesDamaged}
                        type="number"
                        onChange={(e) => {
                          handleDamagedPiecesChange(e.target.value, index);
                        }}/>
                    </TableItem>
                    <TableItem 
                      display={{ xs: 'none', md: 'table-cell' }}
                    >
                      <FormText 
                        name="problemText" 
                        placeholder="" 
                        rows="3" 
                        width={{ xs: "100%", lg: "500px" }}
                        onChange={(e) => {
                          handleProblemTextChange(e.target.value, index);
                        }}
                      />
                    </TableItem>
                    <TableItem 
                      px={0}
                    >
                      <Button 
                        href=""
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          fontWeight: 'bold',
                          justifyContent: "center"
                        }}
                        onClick={() => handleDeleteItem(index)}
                        color="warning"
                        underline="none">
                        <DeleteIcon 
                            sx={{
                                fontSize:"20px!important",
                                paddingX: "10px"
                            }}
                        />
                        <Box 
                          display={{ xs: 'none', md: 'inline-block' }}
                          sx={{
                            whiteSpace: "nowrap"
                          }}
                        >
                          Delete Item
                        </Box>
                      </Button>
                    </TableItem>
                  </TableRow>
                  <TableRow
                      sx={{
                        border: 0,
                        p: 0
                      }}
                    >
                      <TableCell 
                        sx={{
                          border: 0,
                          display: {xs: 'table-cell', md: 'none' }
                        }} 
                      />
                      <TableCell
                        colSpan={6} 
                        sx={{
                          border: 0,
                          display: {xs: 'table-cell', md: 'none' }
                        }}
                      >
                        <FormText 
                          name="problemText" 
                          placeholder="" 
                          rows="3" 
                          width={{ xs: "100%", md: "500px" }}
                          onChange={(e) => {
                            handleProblemTextChange(e.target.value, index);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                </> 
              }
            </Formik>
          ))}
        </TableBody>
      </Table>
      { items.length > 0 &&
        <Button 
          onClick={handleAddItem}
          variant="contained" 
          size="large"
          sx={{
            textAlign:"center",
            fontWeight: 700,
            fontSize: 15,
            paddingX: 4,
            paddingY: "11px",
            background: 'linear-gradient(to right, #4757D6, #8B9BFA)',
            borderRadius: 50
          }}
        >
          + Add New Item
        </Button>
      }
    </TableContainer>
  );
}

TableTitle.propTypes = {
  children: PropTypes.any,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
};

TableTitle.defaultProps = {
  children: "",
  minWidth: 0,
  maxWidth: 1000,
};

TableItem.propTypes = {
  children: PropTypes.any,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
};

TableItem.defaultProps = {
  children: "",
  minWidth: 0,
  maxWidth: 1000,
};
