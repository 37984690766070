/* eslint-disable */
import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Formik, Form } from 'formik';
import Input from '../../components/form/input';
import { DatePicker } from '../../components/form/datePicker';
import { Dropdown } from '../../components/form/dropdown';
import Subtitle from '../../components/subtitle';
import SubParagraph from '../../components/subParagraph';
import ImageUploader from '../../components/imageUploader';
import { retailerValidationSchema } from '../../validations/retailerValidations';

import { RETAILERS } from '../../constants';
import { setPurchaseDate, setRetailerSelected, setRetailerName, uploadProofOfPurchase } from "../../slices/wholesaleSlice";

export default function StepOne() {
  const { purchaseDate, retailerSelected, retailerName, proofOfPurchase, proofOfPurchaseKeys, isOtherSelected } = useSelector((state) => state.wholesale);
  const { isWholesale } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const handleSetPurchaseDate = (value) => {
    dispatch(setPurchaseDate(value.toString()));
  }
  const handleSetRetailerSelected = (event) => {
    dispatch(setRetailerSelected(event.target.value));
  }
  const handleSetRetailerName = (event) => {
    dispatch(setRetailerName(event.target.value));
    
  }
  const handleUploadImage = (data) => {
    dispatch(uploadProofOfPurchase(data));
  }
  return (
    <Container 
      maxWidth="md"
      sx={{
        px: 0
      }}
    >
      <Subtitle>
        Enter purchase information
      </Subtitle>

      <SubParagraph align="justify">
        Brand is commited to your complete satisfaction in conjuction with our valued retail partners.
        If any of your recently purchased items were broken or damaged when opening the packaging, 
        our goal is to replace them for you directly as quickly as possible.
      </SubParagraph>

      <SubParagraph align="justify" mt={3}>
        To accomplish this in the most expedient manner simply provide the following information below 
        and you will be guided through the next steps to tell us about your damaged item(s).
      </SubParagraph>
      <SubParagraph align="justify" mt={3}>
        We will take it from there to arrange a shipment of replacement items. It will be necessary to 
        have a copy of your purchase receipt available to complete the process.
      </SubParagraph>
      <Container 
        maxWidth="xs"
        sx={{
          marginTop: 7,
          px: 0
        }}
      >
        <Formik initialValues = {
            {
              purchaseDate: purchaseDate,
              retailerSelected: retailerSelected,
              retailerName: retailerName,
            }
          }
          validateOnMount = {false}
          validationSchema = {retailerValidationSchema}
        >
          {({
            values, 
            errors
          })=>{
            return(
              <Form>
                <DatePicker onChange={handleSetPurchaseDate} label="PURCHASE DATE" name="purchaseDate" placeholder="Select Day" value={purchaseDate} style={{marginTop: "24px"}}/>
                <Dropdown onChange={handleSetRetailerSelected} label="RETAILER WHERE YOU PURCHASED" name="retailerSelected"  placeholder="Other" options={RETAILERS} value={retailerSelected} style={{marginTop: "24px"}}/>
                {(isOtherSelected) && <Input onChange={handleSetRetailerName} label="RETAILER NAME" name="retailerName" value={retailerName} placeholder="" style={{marginTop: "24px"}}/>}
              </Form>
            )
          }}
        </Formik>
      </Container>
      <Subtitle>
        Proof of Purchase
      </Subtitle>
      <SubParagraph 
        align="center"
      >
        Please attach an image as a proof of purchase.
      </SubParagraph>
      <SubParagraph 
        align="center"
      >
        All images must be .JPEG, .JPG or .PNG with maximum file size of 10 MB.
      </SubParagraph>
      <Box 
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ 
          marginTop: 5,
          align: 'justify' 

        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <ImageUploader 
              title="Image 1"
              imageArray={proofOfPurchase}
              imageKeyArray={proofOfPurchaseKeys}
              imageIndex={0}
              setImageArray={handleUploadImage}
              item_id="proof_of_purchase_1"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ImageUploader 
              title="Image 2 (Optional)"
              imageArray={proofOfPurchase}
              imageKeyArray={proofOfPurchaseKeys}
              imageIndex={1}
              setImageArray={handleUploadImage}
              item_id="proof_of_purchase_2"
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
/* eslint-enable */